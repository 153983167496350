<template>
  <floating-card-header :title="$t('assets.externalKeys.title')" icon="key" class="form__field pt-0 pr-2">
    <template #append>
      <v-dialog v-if="hasScope(organizationId, AuthScope.CAN_UPDATE_ASSETS_EXTERNAL_KEYS)" width="500">
        <template #activator="{ props }">
          <v-btn v-bind="props" icon="add" variant="tonal" color="success"/>
        </template>

        <template #default="{ isActive }">
          <dialog-external-key
            :asset-id="assetId"
            @ok="() => { refresh().then(() => { isActive.value = false }) }"
            @cancel="() => { isActive.value = false }"
          />
        </template>
      </v-dialog>
    </template>
  </floating-card-header>

  <v-list class="w-100 py-0" variant="tonal">
    <v-list-item
      v-for="externalKey of externalKeys"
      :key="externalKey._id"
      class="rounded mb-1 pr-2"
    >
      <template #title>{{ externalKey.application.description }} ({{ externalKey.application.company }})</template>
      <template #default>
        <code>{{ externalKey.value }}</code>
        <v-btn
          variant="text"
          size="x-small"
          icon="content_copy"
          class="mt-n1"
          :aria-label="$t('copyToClipboard')"
          @click="copyKeyToClipboard(externalKey.value)"
        />
      </template>

      <template #append>
        <div class="d-flex">
          <v-dialog v-if="!externalKey.isDefault && hasScope(organizationId, AuthScope.CAN_UPDATE_ASSETS_EXTERNAL_KEYS)" width="500">
            <template #activator="{ props }">
              <v-btn v-bind="props" :disabled="externalKey.isDefault" icon="delete" variant="text"/>
            </template>

            <template #default="{ isActive }">
              <v-card :title="$t('remove')">
                <v-card-text>{{ $t('removeDialogMessage', [ `${externalKey.value} - ${externalKey.application.description} (${externalKey.application.company})` ]) }}</v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn color="primary" @click="() => { isActive.value = false }">{{ $t('cancel') }}</v-btn>
                  <v-btn color="error" @click="() => removeExternalKey(externalKey).then(() => { isActive.value = false })">{{ $t('remove') }}</v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>

          <v-dialog v-if="!externalKey.isDefault || isSuperAdmin" width="500">
            <template #activator="{ props }">
              <v-btn v-bind="props" :id="`manage-${externalKey._id}`" icon="edit" variant="text"/>
            </template>

            <template #default="{ isActive }">
              <dialog-external-key
                :asset-id="assetId"
                :external-key="externalKey"
                @ok="() => { refresh().then(() => { isActive.value = false }) }"
                @cancel="() => { isActive.value = false }"
              />
            </template>
          </v-dialog>
        </div>
      </template>
    </v-list-item>
  </v-list>
</template>

<script setup lang="ts">
import { AuthScope } from "~~/stores/auth.store"

const props = defineProps<{ assetId: string, organizationId: string }>()
const { assetId, organizationId } = toRefs(props)

const $i18n = useI18n()

const assetStore = useAssetStore()
const authStore = useAuthStore()
const mainStore = useMainStore()

const { isSuperAdmin } = storeToRefs(authStore)

const { hasScope } = authStore

const { data: externalKeys, refresh } = useLazyAsyncData("external-keys-asset", () => assetStore.getExternalKeys(assetId.value), { default: () => [] })

const removeExternalKey = async (externalKey: PopulatedExternalKey) => {
  await useExplicitSave(mainStore.notify, $i18n, async () => {
    await assetStore.deleteExternalKey(assetId.value, externalKey._id)
    await refresh()

    return `${$i18n.t("removed")} '${externalKey.value} - ${externalKey.application.description} (${
      externalKey.application.company
    })'`
  }, 'remove')
}

const copyKeyToClipboard = (key: string) => {
  navigator.clipboard.writeText(key)
  mainStore.notify({
    title: $i18n.t("copiedToClipboard"),
    type: "info",
    closable: true,
    timeout: 3000,
  })
}
</script>